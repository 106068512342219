import { REACT_APP_GLOBAL_ROUTE } from 'config'
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom'
import { Viewer } from './view/Viewer'
import './TopSummary.css'

const route = (route = '/') => REACT_APP_GLOBAL_ROUTE + route

export const TopSummary: React.FC = () => {
  return (
    <div className="TopSummary">
      <ul>
        <li>
          <Link to={route('/oa')}>Occasions Assets</Link>
        </li>
        <li>
          <Link to={route('/gifs')}>Gifs</Link>
        </li>
      </ul>
    </div>
  )
}

export const TopSummaryOrWhat: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>

        <Route path={route('/')} exact>
          <TopSummary />
        </Route>

        <Route path={route('/oa')} exact>
          <Viewer collectionTags={['Occasions Assets']}/>
        </Route>

        <Route path={route('/gifs')} exact>
          <Viewer collectionTags={['other']}/>
        </Route>

      </Switch>
    </BrowserRouter>
  )
}