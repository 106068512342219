import { IMAGE_PREFIX, SERVER_ROOT } from '../config'

/**
 * Add SERVER_HOST & replace white spaces.
 */
export const getHostUrl = (url: string | undefined) => {
  return url ? (SERVER_ROOT + url.replace(/\s/g, '%20')) : ''
}

/**
 * Retrieve the name from the url (add prefix, remove format suffix)
 */
export const imageNameFromURL = (url: string = '', {
  removeFormatSuffix = true,
  removeExtension = true,
  useImagePrefix = true,
} = {}) => {
  let name = url.split('/').pop()!
  if (removeExtension) {
    name = name?.replace(/\.(jpe?g|mp4)$/, '')
  }
  if (removeFormatSuffix) {
    name = name?.replace(/([_-]\dx\d+)$/, '')
  }
  if (useImagePrefix) {
    name = IMAGE_PREFIX + name
  }
  return name
}

export const splitImageName = (name: string) => {
  name = name.split('/').pop()!
  name = name.replace(IMAGE_PREFIX, '')
  let collectionPrefix = ''
  name = name.replace(/^\w{2}-/, match => {
    collectionPrefix = match
    return ''
  })
  let extension = ''
  name = name.replace(/\.\w+$/, match => {
    extension = match
    return ''
  })
  let formatSuffix = ''
  name = name.replace(/[-_]\d+[_x]\d+$/, match => {
    formatSuffix = match
    return ''
  })
  let versionSuffix = ''
  name = name.replace(/-\w$/, match => {
    versionSuffix = match
    return ''
  })
  return {
    prefix: IMAGE_PREFIX,
    collectionPrefix,
    versionSuffix,
    formatSuffix,
    base: name,
    extension,
  }
}
