import React from 'react'
import { useCredientialsStore } from "./store/useCredientialsStore"
import './Login.css'

export const Login: React.FC = () => {

  const passwordInputRef = React.useRef<HTMLInputElement>(null)
  const { password, username, setCredentials } = useCredientialsStore()
  const [state, setState] = React.useState({ password, username })

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        setCredentials(state.username, state.password)
      }
    }
    window.addEventListener('keydown', onKeyDown, { capture: true })
    return () => {
      window.removeEventListener('keydown', onKeyDown, { capture: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="Login" onKeyDown={e => console.log(e)}>
      <div>
        <span>username</span>
        <input
          value={state.username}
          type="text"
          placeholder="username"
          onChange={e => setState({ ...state, username: e.target.value })}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              passwordInputRef.current?.focus()
            }
          }}
        />
      </div>
      <div>
        <span>password</span>
        <input
          ref={passwordInputRef}
          value={state.password}
          type="password"
          placeholder="password"
          onChange={e => setState({ ...state, password: e.target.value })}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              setCredentials(state.username, state.password)
            }
          }}
        />
      </div>
      <button onClick={() => {
        setCredentials(state.username, state.password)
      }}>login</button>
    </div>
  )
}
