import React from 'react'
import * as state from 'client/state/state'
import { ModalImageSolo } from './ModalImageSolo'
import { ModalImageComment } from './ModalImageComment'
import './Modal.css'

type Mode = 
  | 'none' 
  | 'image-solo'
  | 'image-comment'

interface ModalState {
  mode: Mode
  collectionIndex: number
  imageIndex: number
}

interface IModal extends ModalState {
  setModal: (props: Partial<ModalState>) => void
  closeModal: () => void
  nextImage: () => void
}
export const ModalContext = React.createContext<IModal>(null!)

export const Escaper: React.FC = ({ children }) => {
  const { closeModal } = React.useContext(ModalContext)
  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        closeModal()
      }
    }
    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  })
  return (
    <>{children}</>
  )
}

export const ModalProvider: React.FC = ({ children }) => {
  const [modal, setState] = React.useState<ModalState>(() => ({
    mode: 'none',
    collectionIndex: NaN,
    imageIndex: NaN,
  }))
  const setModal = (props: Partial<ModalState>) => setState({ ...modal, ...props })
  const closeModal = () => setState({ ...modal, mode: 'none' })
  const nextImage = () => {}
  
  const { mode, collectionIndex, imageIndex } = modal
  const image = isNaN(collectionIndex) === false && isNaN(imageIndex) === false
    ? state.useImage(collectionIndex, imageIndex) : null

  const useModal = !!image

  return (
    <ModalContext.Provider value={{ ...modal, setModal, closeModal, nextImage }}>
      {children}
      {useModal && (
        <Escaper>
          {image && mode === 'image-solo' && (
            <ModalImageSolo {...{ image, collectionIndex, imageIndex }}/>
          )}
          {image && mode === 'image-comment' && (
            <ModalImageComment {...{ image, collectionIndex, imageIndex }}/>
          )}
        </Escaper>
      )}
    </ModalContext.Provider>
  )
}
