import React from 'react'

/**
 * Restore scroll position from the previous session.
 */
export const usePreviousScroll = () => {
  React.useEffect(() => {
    const element = document.scrollingElement! as HTMLElement
    const previousScroll = localStorage.getItem('previousScroll')
    if (previousScroll) {
      element.scrollTop = parseFloat(previousScroll)
    }
    document.addEventListener('scroll', () => localStorage.setItem('previousScroll', element.scrollTop.toFixed()))
  }, [])
}
