
interface Callback {
  timeoutId: number
  timestamp: number
}

const map = new Map<any, Callback>();

/**
 * For a given key invoke a deferred callback. Only the last callback is invoked 
 * (previous ones are discarded).
 */
export const deferred = (key: any, callback: () => void, {
  delay = 1000
} = {}) => {
  let timestamp = Date.now()

  if (map.has(key)) {
    const current = map.get(key)!
    timestamp = current.timestamp
    delay -= Date.now() - timestamp
    window.clearTimeout(current.timeoutId)
  }

  const deferredCallback = () => {
    map.delete(key)
    callback()
  }
  const timeoutId = window.setTimeout(deferredCallback, delay)
  map.set(key, { timeoutId, timestamp })
}