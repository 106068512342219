import React, { FC } from 'react'
import lodash from 'lodash'
import ContentEditable from 'react-contenteditable'
import { isDevelopment } from '../../config'
import * as state from '../state/state'
import { Thumbnail } from './Thumbnail'
import { handleDropFile } from './handleFile'
import { someParent } from '../utils/misc'
import './Page.css'


export const Page: FC<{
  collectionIndex: number
  imageIndexes: number[]
  collectionPageIndex: number
  extraTitle?: string
  pagination?: string
}> = ({
  collectionIndex, 
  imageIndexes,
  collectionPageIndex = 0,
  extraTitle = '',
  pagination = '',
}) => {
  const page = state.useCollection(collectionIndex)
  const divRef = React.useRef<HTMLDivElement>(null)
  const onBlur = (e: React.FocusEvent<HTMLDivElement>): void => {
    const name = (e.target as HTMLDivElement).innerText
    state.updateCollection(collectionIndex, { name })
  }
  const handleDrag = async (e: React.DragEvent<HTMLDivElement>) => {
    const thumbailIsParent = someParent(e.target as HTMLElement, element => element.classList.contains('Thumbnail'))
    e.preventDefault()
    if (e.type === 'dragover') {
      divRef.current!.classList.toggle('drag-over', !thumbailIsParent)
    }
    if (e.type === 'dragleave') {
      divRef.current!.classList.remove('drag-over')
    }
    if (e.type === 'drop') {
      divRef.current!.classList.toggle('drag-over', false)
      if (!thumbailIsParent) {
        for (const file of e.dataTransfer.files) {
          const url = await handleDropFile(file)
          if (url) {
            state.appendImage(collectionIndex, { url })
          }
        }
      }
    }
  }

  const pageId = lodash.kebabCase(page.name) + (collectionPageIndex === 0 ? '' : `-${collectionPageIndex + 1}`)
  const editMode = isDevelopment

  return (
    <div
      ref={divRef}
      className="Page Thumbnails"
      id={pageId}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrag}
    >
      <div className="Border absolute-fill"/>
      <header>
        <div className="Top">
          <a href="#summary">Edelweiss Occasions Assets</a>
        </div>
        <h1
          onClick={() => window.location.hash = pageId}
        >
          <ContentEditable
            tagName="span"
            contentEditable={editMode}
            onChange={e => console.log(e.target.value)}
            html={page.name}
            onBlur={onBlur} />
          <span className="ExtraTitle">{extraTitle}</span>
        </h1>
      </header>
      <main>
        {imageIndexes.map(index => {
          return (
            <Thumbnail key={index} collectionIndex={collectionIndex} imageIndex={index} />
          )
        })}
      </main>
      <footer>
        <div className="Bottom">hipoly 2021 {pagination}</div>
      </footer>
    </div>
  )
}
