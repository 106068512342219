import { Image } from 'types'

export const imageFilter = (image: Image | null) => {
  if (!image) {
    return false
  }

  if (image.status && image.status.visible === false) {
    return false
  }

  return true
}