import create from 'zustand'
import { persist } from "zustand/middleware"

export const useCredientialsStore = create<{
  username: string
  password: string
  setCredentials: (username: string, password: string) => void
}>(persist(set => ({
  username: '',
  password: '',
  setCredentials: (username: string, password: string) => set({ username, password })
}), {
  name: 'credentials',
  getStorage: () => sessionStorage,
}))
