import React from 'react'
import copy from 'copy-to-clipboard'
import { Image } from 'types'
import * as state from 'client/state/state'
import { getHostUrl, imageNameFromURL, splitImageName } from 'utils/app-utils'
import { useIsMobile } from 'client/utils/useMobileEffect'
import { ModalContext } from './Modal'
import { Icon } from '../atoms/Icon'
import { LoadingIndicator } from '../atoms/LoadingIndicator'
import { isDevelopment } from 'config'
import './ModalImageSolo.css'
import { VideoLoop } from '../atoms/VideoLoop'

const ImageElement: React.FC<{
  src?: string,
  className?: string,
}> = ({
  src = '',
  className = '',
}) => {
  const divRef = React.useRef<HTMLDivElement>(null)
  const divDownloadRef = React.useRef<HTMLDivElement>(null)
  const imgRef = React.useRef<HTMLImageElement>(null)
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const isMobile = useIsMobile()
  const downloadName = imageNameFromURL(src, { removeFormatSuffix: false, removeExtension: false })
  const [state, setState] = React.useState({ loading: false })
  React.useEffect(() => {
    const div = divRef.current!
    div.onpointerover = () => {
      divDownloadRef.current?.classList.add('visible')
      divDownloadRef.current?.classList.remove('hidden')
    }
    div.onpointerout = () => {
      divDownloadRef.current?.classList.remove('visible')
      divDownloadRef.current?.classList.add('hidden')
    }
    const img = imgRef.current
    if (img) {
      setState({ loading: img.complete === false })
      img.onload = () => setState({ loading: false }) 
    }
  }, [])

  const LinkWrapper: React.FC = ({ children }) => {
    const onClick = () => {
      fetch(getHostUrl(`/stats/increment/${downloadName}`), { method: 'POST' })
    }
    return isMobile === false ? (
      <a href={src} download={downloadName} target="_blank" rel="noreferrer" onClick={onClick}>
        {children}
      </a>
    ) : (
      <>
        {children}
      </>
    )
  }

  const isVideo = /\.mp4$/.test(src)

  return (
    <div
      ref={divRef}
      className={`ImageElement ${className}`}
    >
      <LinkWrapper>
        {isVideo
          ? <VideoLoop ref={videoRef} src={src} />
          : <img ref={imgRef} src={src} alt="" />
        }
        {state.loading && (
          <LoadingIndicator className="absolute-center" size="2em" style={{ color: 'var(--gold)' }}/>
        )}
        {isMobile === false && (
          <div ref={divDownloadRef} className="Download button flex-center hidden">
            <span>{downloadName}</span>
            <Icon icon="mdi:download" fontSize={24}/>
          </div>
        )}
      </LinkWrapper>
    </div>
  )
}

const NameParts: React.FC<{
  name: string,
}> = ({
  name,
}) => {
  const parts = splitImageName(name)
  const divRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const div = divRef.current!
    let scope: HTMLElement | null = null
    const onOver = (event: PointerEvent): void => {
      scope?.classList.remove('active')
      scope = event.target as HTMLElement
      scope.classList.add('active')
    }
    const onOut = (): void => {
      scope?.classList.remove('active')
    }
    const onClick = (event: MouseEvent): void => {
      const info = (event.target as HTMLElement).innerText.replace(/\s/g, '')
      copy(info)
    }
    div.addEventListener('pointerover', onOver)
    div.addEventListener('pointerout', onOut)
    div.addEventListener('click', onClick)
    return () => {
      div.removeEventListener('pointerover', onOver)
      div.removeEventListener('pointerout', onOut)
      div.removeEventListener('click', onClick)
    }
  }, [])

  return (
    <div className="NameParts" ref={divRef}>
      <div className="Part">
        <span>{parts.prefix}</span>
        <div className="Part">
          <span>{parts.collectionPrefix}</span>
          <div className="Part">
            {parts.versionSuffix
              ? (<>
                <div className="Part">{parts.base}</div>
                <span>{parts.versionSuffix}</span>
              </>)
              : (
                <span>{parts.base}</span>
              )
            }
          </div>
        </div>
      </div>
      {/* <Icon icon="mdi:clipboard-check-outline" fontSize={24}/> */}
    </div>
  )
}

export const ModalImageSolo: React.FC<{
  image: Image
  collectionIndex: number
  imageIndex: number
}> = ({
  image,
  collectionIndex,
  imageIndex,
}) => {

  const divRef = React.useRef<HTMLDivElement>(null)
  const { setModal, closeModal } = React.useContext(ModalContext)
  const fullurl = getHostUrl(image.url)
  console.log(image)

  React.useEffect(() => {
    document.scrollingElement?.classList.add('modal-open')
    return () => document.scrollingElement?.classList.remove('modal-open')
  }, [])

  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'ArrowRight') {
        const next = state.getNextImage(collectionIndex, imageIndex)
        if (next) {
          setModal(next)
        }
      }
      if (e.code === 'ArrowLeft') {
        const previous = state.getPreviousImage(collectionIndex, imageIndex)
        if (previous) {
          setModal(previous)
        }
      }
    }
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  })

  const fullurl4x5 = fullurl?.replace('9x16', '4x5')

  const isMobile = useIsMobile()
  const [mobileClickCount, setMobileClickCount] = React.useState(0)
  const basename = imageNameFromURL(fullurl)
  const zip = basename + '.zip'

  return (
    <div
      ref={divRef}
      className="Modal ImageSolo"
    >
      <div
        className="Images"
        onClick={e => {
          if (!isMobile) {
            if ((e.target as HTMLElement).classList.contains('Images')) {
              setModal({ mode: 'none' })
            }
          } else {
            const countNew = (mobileClickCount + 1) % 3
            setMobileClickCount(countNew)
            if (countNew === 2) {
              setModal({ mode: 'none' })
            }
          }
        }}
      >
        {!isMobile ? (
          <>
            <ImageElement className="format9x16" src={fullurl}/>
            <ImageElement className="format4x5" src={fullurl4x5}/>
          </>
        ) : (
          <ImageElement
            className={mobileClickCount === 0 ? 'format9x16' : 'format4x5'}
            src={mobileClickCount === 0 ? fullurl : fullurl4x5}
          />
        )}
      </div>
      
      <footer className="flex-center">
        <a className="flex-center" href={getHostUrl(`/zip/${zip}`)} download>
          <h3>{state.useCollection(collectionIndex).name}</h3>
          <span>{zip}</span>
          <Icon icon="mdi:download" fontSize={24} inline/>
        </a>
        {isDevelopment && !isMobile && (
          <NameParts name={basename}/>
        )}
      </footer>

      <div className="CloseModal button" onClick={closeModal}>
        <Icon icon="mdi:close" hover margin={12} fontSize={24}/>
      </div>
    </div>
  )
}
