import React from 'react'
import { safeClassName } from 'client/utils/misc'
import { Icon as Iconify } from '@iconify/react'
import './Icon.css'

export const Icon: React.FC<React.ComponentProps<typeof Iconify> & {
  gold?: boolean
  hover?: boolean
  margin?: number
}> = ({
  gold, 
  hover, 
  margin = 0,
  ...props
}) => {
  return (
    <div className={safeClassName('Icon flex-center', { gold, hover })} style={{ padding: `${margin}px` }}>
      <div>
        <Iconify {...props} />
      </div>
    </div>
  )
}
