import { SERVER_ROOT } from '../../config'
import * as state from '../state/state'

export const handleDropFile = async (file: File) => {
  if (!file) {
    return null
  }
  const { name, size } = file
  await state.rebuildFileIndex()
  const response = await fetch(`${SERVER_ROOT}/file-info`, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ name, size })
  })
  if (response.status === 200) {
    const fileInfo = await response.json()
    if (fileInfo) {
      const url = '/' + fileInfo.dir + fileInfo.name
      return url
    }
  }
  return null
}
