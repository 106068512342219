import { safeClassName } from 'client/utils/misc'
import React from 'react'
import './LoadingIndicator.css'

const getData = (r: number, aperture = 1 / 3) => {
  const x = r * Math.cos(aperture * Math.PI * 2)
  const y = r * Math.sin(aperture * Math.PI * 2)
  const large = aperture > 0.5 ? 1 : 0
  return `M ${r} 0 A ${r} ${r} 0 ${large} 1 ${x.toFixed(4)} ${y.toFixed(4)}`
}
export const LoadingIndicator: React.FC<React.HTMLAttributes<SVGSVGElement> & {
  size?: string
  aperture?: number | (() => number),
}> = ({
  size = 'inherit',
  style,
  className,
  aperture = 1 / 3,
  ...props
}) => {

  const ref = React.useRef<SVGSVGElement>(null)
  React.useEffect(() => {
    let angle = 0
    const path = ref.current?.querySelector('path')!
    const loop = () => {
      frameId = window.requestAnimationFrame(loop)
      angle += 1
      path.setAttributeNS(null, 'transform', `rotate(${angle % 360})`)
      const a = typeof aperture === 'function' ? aperture() : aperture
      path.setAttributeNS(null, 'd', getData(10, a))
    }
    let frameId = window.requestAnimationFrame(loop)
    return () => window.cancelAnimationFrame(frameId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      className={safeClassName('LoadingIndicator', className)}
      width="2em"
      height="2em"
      viewBox="-12 -12 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      style={{ ...style, fontSize: size }}
      {...props}
    >
      <circle r={10}  opacity={0.1} />
      <path />
    </svg>
  )
}
