import React from 'react'
import { SERVER_ROOT } from 'config'
import { Data, Collection, Image } from 'types'
import { imageFilter } from './state-utils'

export * from './state-utils'

console.log(`backend is ${SERVER_ROOT}`)

let data: Data|null = null
let status: 'none' | 'loading' | 'complete' = 'none'
const onLoad = new Set<() => void>() 
const load = async () => {
  status = 'loading'
  {
    // rebuild index first
    const response = await fetch(SERVER_ROOT + '/file-rebuild-index', { method: 'POST' })
    console.log(await response.json())
  }
  const response = await fetch(SERVER_ROOT + '/get-data')
  data = await response.json()
  const thumbnailCount = data!.collections.reduce((sum, c) => sum + c.images.length, 0)
  console.log({ collectionCount: data!.collections.length, thumbnailCount })
  status = 'complete'
  for (const cb of onLoad) {
    cb()
  }
}

const save = async ({
  thenLoad = false
} = {}) => {
  const response = await fetch(SERVER_ROOT + '/update-data', {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
  const message = await response.json()
  if (thenLoad) {
    load()
  }
  return message
}

export const useUnsafeData = () => {
  const [innerData, setInnerData] = React.useState<Data|null>(data)
  if (status === 'none') {
    load()
  }
  React.useEffect(() => {
    onLoad.add(() => setInnerData(data))
  }, [])
  return innerData
}

const initError = (methodName: string) => new Error(`"${methodName}" must be called only after data has been initialized.`)

export const useData = () => {
  if (data === null) {
    throw initError('useData')
  }
  return data
}

export const useCollection = (collectionIndex: number) => {
  if (data === null) {
    throw initError('useCollection')
  }
  return data.collections[collectionIndex]
}

export const getImageTotalCount = (collections: Collection[]) => {
  return (
    collections
      .map(collection => collection.images.filter(imageFilter).length)
      .reduce((x, y) => x + y)
  )
}
export const useImageTotalCount = () => {
  if (data === null) {
    throw initError('useImageTotalCount')
  }
  return getImageTotalCount(data.collections)
}

export const useImage = (collectionIndex: number, imageIndex: number) => {
  if (data === null) {
    throw initError('useImage')
  }
  return data.collections[collectionIndex]?.images[imageIndex]
}
export const getImage = (collectionIndex: number, imageIndex: number) => {
  if (data === null) {
    throw initError('getImage')
  }
  return data.collections[collectionIndex]?.images[imageIndex]
}


export const rebuildFileIndex = async () => {
  const response = await fetch(SERVER_ROOT + '/file-rebuild-index', { method: 'POST' })
  return await response.json()
}

export const updateCollection = (collectionIndex: number, newProps: Partial<Collection>) => {
  if (data === null) {
    throw initError('updatePage')
  }
  Object.assign(data.collections[collectionIndex], newProps)
  return save({ thenLoad: true })
}

export const moveCollection = (collectionIndex: number, {
  newIndex = NaN,
  offset = NaN,
}) => {
  if (data === null) {
    throw initError('moveCollection')
  }

  if (isNaN(offset) === false) {
    const [collection] = data.collections.splice(collectionIndex, 1)
    data.collections.splice(collectionIndex + offset, 0, collection)
    return save({ thenLoad: true })
  }

  if (isNaN(newIndex) === false) {
    const [collection] = data.collections.splice(collectionIndex, 1)
    data.collections.splice(newIndex, 0, collection)
    return save({ thenLoad: true })
  }
}

export const appendImage = (collectionIndex: number, newImage: Image) => {
  if (data === null) {
    throw initError('appendImage')
  }
  data.collections[collectionIndex].images.push(newImage)
  return save({ thenLoad: true })
}

export const updateImage = (collectionIndex: number, imageIndex: number, newProps: Partial<Image>, {
  thenLoad = true,
} = {}) => {
  if (data === null) {
    throw initError('updateImage')
  }
  const { images } = data.collections[collectionIndex]
  while (images.length < imageIndex) {
    images.push(null)
  }
  if (!images[imageIndex]) {
    if ('url' in newProps) {
      images[imageIndex] = newProps as Image
    } else {
      throw new Error('invalid newProps')
    }
  } else {
    Object.assign(images[imageIndex], newProps)
  }
  return save({ thenLoad })
}

export const deleteImage = (collectionIndex: number, imageIndex: number) => {
  if (data === null) {
    throw initError('deleteImage')
  }
  data.collections[collectionIndex].images.splice(imageIndex, 1)
  return save({ thenLoad: true })
}

export const insertImageBefore = (collectionIndex: number, imageIndex: number) => {
  if (data === null) {
    throw initError('deleteImage')
  }
  data.collections[collectionIndex].images.splice(imageIndex, 0, null)
  return save({ thenLoad: true })
}

export const insertImageAfter = (collectionIndex: number, imageIndex: number) => {
  if (data === null) {
    throw initError('deleteImage')
  }
  data.collections[collectionIndex].images.splice(imageIndex + 1, 0, null)
  return save({ thenLoad: true })
}

export const moveImage = (collectionIndex: number, imageIndex: number, offset: number) => {
  if (data === null) {
    throw initError('moveImage')
  }
  if (offset === 0) {
    return
  }
  const { images } = data.collections[collectionIndex]
  const image = images.splice(imageIndex, 1)
  const index = imageIndex + offset
  images.splice(index, 0, ...image)
  return save({ thenLoad: true })
}

export const getSiblingImage = (collectionIndex: number, imageIndex: number, {
  next = true
} = {}) => {
  if (data === null) {
    throw initError('getNextImage')
  }
  let ci = collectionIndex, ii =imageIndex
  let s = 0
  while (s++ < 10000) {
    if (ci < 0 || ci >= data.collections.length) {
      return null
    }
    const images = data.collections[ci].images
    ii += next ? 1 : -1
    if (ii >= images.length) {
      ci += 1
      ii = -1
      continue
    }
    if (ii < 0) {
      ci += -1
      if (ci < 0 || ci >= data.collections.length) {
        return null
      }
      ii = data.collections[ci].images.length
      continue
    }
    const image = images[ii]
    if (image) {
      return { collectionIndex: ci, imageIndex: ii, image }
    }
  }
  throw new Error('that should not happen!')
}

export const getNextImage = (collectionIndex: number, imageIndex: number) => 
  getSiblingImage(collectionIndex, imageIndex, { next: true })

export const getPreviousImage = (collectionIndex: number, imageIndex: number) => 
  getSiblingImage(collectionIndex, imageIndex, { next: false })
