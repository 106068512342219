import React, { FC } from 'react'
import copy from 'copy-to-clipboard'
import { useIsMobile } from 'client/utils/useMobileEffect'
import { REACT_APP_SHOW_COMMENT } from 'config'
import { isShortClick } from 'client/utils/pointer'
import * as state from '../state/state'
import { getHostUrl, imageNameFromURL } from '../../utils/app-utils'
import { VideoLoop } from './atoms/VideoLoop'
import { handleDropFile } from './handleFile'
import { ModalContext } from './modal/Modal'
import './Thumbnail.css'

const Wrapper: FC<{
  url?: string
} & React.HTMLProps<HTMLDivElement>> = ({ 
  url,
  children,
  onClick,
  ...props
}) => {
  const divRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!url) return
    const img = document.createElement('img')
    img.src = url
    img.onload = () => {
      const wrapperRect = divRef.current!.getBoundingClientRect()
      const xRatio = wrapperRect.width / img.naturalWidth
      const yRatio = wrapperRect.height / img.naturalHeight
      const ratio = Math.min(xRatio, yRatio)
      const width = Math.floor(img.naturalWidth * ratio)
      const height = Math.floor(img.naturalHeight * ratio)
      const border = divRef.current!.querySelector('.Border') as HTMLDivElement
      border.style.width = width + 'px'
      border.style.height = height + 'px'
      border.style.left = ((wrapperRect.width - width) * 0.5) + 'px'
      border.style.top = ((wrapperRect.height - height) * 0.5) + 'px'
      const actions = divRef.current!.querySelector('.Actions') as HTMLDivElement
      actions.style.width = width + 'px'
      actions.style.height = height + 'px'
      actions.style.left = ((wrapperRect.width - width) * 0.5) + 'px'
      actions.style.top = ((wrapperRect.height - height) * 0.5) + 'px'
    }
  }, [url])

  const isVideo = url ? /\.mp4$/.test(url) : false

  return (
    <div
      {...props}
      ref={divRef}
      className="Wrapper"
    >
      {url && (
        isVideo ? <VideoLoop src={url} onClick={onClick}/>
        : <img src={url} alt="" onClick={onClick}/>
      )}
      <div className="Border absolute-fill" />
      <div className="Actions absolute-fill">
        {children}
      </div>
    </div>
  )
}

const CommentPreview: React.FC<{
  collectionIndex: number
  imageIndex: number
}> = ({
  collectionIndex,
  imageIndex,
}) => {
  const comments = state.useImage(collectionIndex, imageIndex)?.comments
  const { setModal } = React.useContext(ModalContext)
  if (!comments) {
    return null
  }
  return (
    <div 
      className="CommentPreview button"
      onClick={() => setModal({ collectionIndex, imageIndex, mode: 'image-comment' })}
    >
      {comments.map((comment, index) => (
        <div key={index}>({comment.author}) {comment.comment}</div>
      ))}
    </div>
  )
}

export const Thumbnail: FC<{
  collectionIndex: number
  imageIndex: number
}> = ({
  collectionIndex,
  imageIndex,
}) => {
  const collection = state.useCollection(collectionIndex)
  const image = collection.images[imageIndex]
  const url = image?.url as string | undefined

  const divRef = React.useRef<HTMLDivElement>(null)
  const handleDrag = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (e.type === 'dragover') {
      divRef.current!.classList.add('drag-over')
    }
    if (e.type === 'dragleave') {
      divRef.current!.classList.remove('drag-over')
    }
    if (e.type === 'drop') {
      divRef.current!.classList.remove('drag-over')
      const file = e.dataTransfer.files[0]
      const url = await handleDropFile(file)
      if (url) {
        state.updateImage(collectionIndex, imageIndex, { url })
      }
    }
  }

  const isMobile = useIsMobile()
  const fullurl = getHostUrl(url)
  const [showFullpath, setShowFullpath] = React.useState(false)
  const { setModal } = React.useContext(ModalContext)
  const dev = process.env.NODE_ENV === 'development'
  return (
    <div
      ref={divRef}
      className="Thumbnail button"
      onDrag={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrag}
    >
      <Wrapper
        url={fullurl}
        onClick={e => {
          if (e.shiftKey) {
            if (image?.originalFilePath) {
              window.open(`file://${image.originalFilePath}`)
            }
          } else {
            setModal({ mode: 'image-solo', collectionIndex, imageIndex })
          }
        }}
      >
        {dev && isMobile === false && (
          <>
            <span style={{ backgroundColor: '#fff9', padding: '2px' }}>{collection.images[imageIndex]?.author}</span>
            <button onClick={() => copy(image?.originalFilePath ?? 'none')}>copy original file path</button>
            <button onClick={() => state.deleteImage(collectionIndex, imageIndex)}>↯ delete</button>
            <button onClick={() => state.insertImageBefore(collectionIndex, imageIndex)}>← insert</button>
            <button onClick={() => state.insertImageAfter(collectionIndex, imageIndex)}>→ insert</button>
            <button onClick={() => state.moveImage(collectionIndex, imageIndex, -1)}>← move</button>
            <button onClick={() => state.moveImage(collectionIndex, imageIndex, 1)}>→ move</button>
            <button onClick={() => state.updateImage(collectionIndex, imageIndex, { status: { visible: false, comment: 'not selected' }})}>Not selected</button>
            <button onClick={() => { state.rebuildFileIndex(); alert('file index has been rebuilt') }}>↻ rebuild file index</button>
          </>
        )}
        {REACT_APP_SHOW_COMMENT && (
          <>
            <CommentPreview {...{ collectionIndex, imageIndex }}/>
            <button onClick={() => setModal({ mode: 'image-comment', collectionIndex, imageIndex })}>comment</button>
          </>
        )}
      </Wrapper>
      <p 
        className={showFullpath ? 'minimized' : ''} 
        onClick={() => isShortClick() && setShowFullpath(!showFullpath)}
      >
        {url ? (
          showFullpath
            ? url
            : imageNameFromURL(url)
          ) : 'xxx'
        }
      </p>
    </div>
  )
}

