import React from 'react'
import qs from 'qs'
import { SERVER_ROOT } from 'config'
import { useMobileEffect } from './utils/useMobileEffect'
import { useCredientialsStore } from './store/useCredientialsStore'
import { TopSummaryOrWhat } from './TopSummary'
import { Login } from './Login'
import './App.css';
import './mobile.css'
import './cheat'

const Auth: React.FC = () => {
  const { username, password } = useCredientialsStore()
  const search = qs.parse(window.location.search.substring(1))
  const ok = (search.skipAuth === '1') || (username === 'edel' && password === 'weiss')
  return (ok 
    ? <TopSummaryOrWhat />
    : <Login />
  )
}

const Centered: React.FC = ({ children }) => (
  <div className="absolute-fill flex-center" style={{ textAlign: 'center' }}>{children}</div>
)

const App: React.FC = () => {
  const [serverStatus, setServerStatus] = React.useState<'init' | 'error' | 'ok'>('init')
  React.useEffect(() => {
    fetch(SERVER_ROOT + '/status')
    .then(() => setServerStatus('ok'))
    .catch(() => setServerStatus('error'))
  }, [])

  React.useEffect(() => {
    const hostname = window.location.hostname
    if (/edelweiss.(hipoly|josephm).fr/.test(hostname)) {
      const script = document.createElement('script')
      script.defer = true
      script.dataset.domain = hostname
      script.src = 'https://plausible.io/js/plausible.js'
      document.head.append(script)
    }
  }, [])

  const divRef = React.useRef<HTMLDivElement>(null!)
  useMobileEffect(isMobile => divRef.current.classList.toggle('mobile', isMobile))

  return (
    <div className="App" ref={divRef}>
      {
        serverStatus === 'init' ? <Centered>Connecting to the server...</Centered> :
        serverStatus === 'error' ? <Centered>Server is down!<br/>Please check server is online.</Centered> :
        <Auth />
      }
    </div>
  )
}

export default App
