export const fillup = <T,>(array: T[], length: number, defaultValue: T) => {
  const length2 = Math.max(0, length - array.length)
  const array2 = new Array(length2).fill(defaultValue) as T[]
  return [...array, ...array2]
}

export function* enumerateParentElement(target: HTMLElement | null, {
  includeSelf = true,
} = {}) {
  let current = target
  if (includeSelf === false) {
    if (current) {
      current = (current as HTMLElement).parentElement
    }
  }
  while (current) {
    yield current
    current = (current as HTMLElement).parentElement
  }
}

export function someParent(target: HTMLElement, predicate: (node: HTMLElement) => boolean, {
  includeSelf = true,
} = {}) {
  for (const node of enumerateParentElement(target, { includeSelf })) {
    if (predicate(node)) {
      return true
    }
  }
  return false
}

export const safeClassName = (...names:(string | { [key: string]: any } | undefined)[]) => {
  return (
    names
    .flat()
    .map(arg => {
      if (arg && typeof arg === 'object') {
        return Object.entries(arg).map(([key, value]) => !!value && key)
      }
      return arg
    })
    .flat()
    .filter(arg => arg && typeof arg === 'string')
    .join(' ')
  )
}


Object.assign(window, { enumerateParent: enumerateParentElement, someParent })
