import React from 'react'
import lodash from 'lodash'
import { IMAGES_PER_PAGE } from 'config'
import { Collection } from 'types'
import * as state from 'client/state/state'
import { ModalProvider } from './modal/Modal'
import { Page } from './Page'
import { usePreviousScroll } from '../utils/usePreviousScroll'
import './Viewer.css'

export const SummaryPage: React.FC<{
  collections: Collection[]
}> = ({
  collections
}) => {
  return (
    <div className="Summary Page" id="summary">
      <header>
        <div className="Top">
          <a href="#summary">Edelweiss Occasions Assets</a>
        </div>
        <h1>Edelweiss Occasions Assets</h1>
      </header>
      <main>
        <ul>
          {collections.map((collection, index) => (
            <li key={index}>
              <a href={`#${lodash.kebabCase(collection.name)}`}>
                <span>#{index + 1} {collection.name}</span>
                <span className="Count">({collection.images.filter(state.imageFilter).length})</span>
              </a>
            </li>
          ))}
        </ul>
        <p>{collections.length} collections, {state.getImageTotalCount(collections)} photos</p>
      </main>
      <footer>
        <div className="Bottom">hipoly 2021 - 01</div>
      </footer>
    </div>
  )
}

const Pages: React.FC<{
  collections: Collection[]
}> = ({
  collections
}) => {

  usePreviousScroll()

  type Entry = { 
    collectionIndex: number
    imageIndexes: number[]
    collectionPageIndex: number
    collectionPageMax: number
  }
  const entries = collections.map(collection => {
    const { index: collectionIndex } = collection
    const pages: Entry[] = []
    let imageIndexes: number[] = []
    for (let i = 0, max = collection.images.length; i < max; i++) {
      const image = collection.images[i]
      // Tricky: null image are accepted (for edit purpose), but not "filtered out" images.
      if (image === null || state.imageFilter(image)) {
        imageIndexes.push(i)
      }
      if (imageIndexes.length === IMAGES_PER_PAGE) {
        pages.push({ collectionIndex, imageIndexes, collectionPageIndex: pages.length, collectionPageMax: -1 })
        imageIndexes = []
      }
    }
    if (imageIndexes.length > 0) {
      pages.push({ collectionIndex, imageIndexes, collectionPageIndex: pages.length, collectionPageMax: -1 })      
    }
    for (const page of pages) {
      page.collectionPageMax = pages.length
    }
    return pages
  })

  return (
    <>
      <SummaryPage collections={collections}/>
      {entries.flat().map((entry, index) => (
        <Page
          key={index}
          collectionIndex={entry.collectionIndex}
          imageIndexes={entry.imageIndexes}
          collectionPageIndex={entry.collectionPageIndex}
          extraTitle={`(${entry.collectionPageIndex + 1}/${entry.collectionPageMax})`}
          pagination={'- ' + (index + 2).toFixed().padStart(2, '0')}
        />
      ))}
    </>
  )
}

const Viewer: React.FC<{
  collectionTags?: string[]
}> = ({
  collectionTags = null,
}) => {
  const data = state.useUnsafeData()

  const collections = React.useMemo(() => {
    if (!data) {
      return []
    }

    if (!collectionTags) {
      return [...data.collections]
    }

    return data.collections.filter(collection => (
      collection.tags.some(tag => collectionTags.includes(tag))
    ))
  }, [collectionTags, data])

  return (
    <div className="Viewer">
      {data
        ? (
          <ModalProvider>
            <Pages collections={collections}/>
          </ModalProvider>
        )
        : 'loading...'
      }
    </div>
  )
}

export {
  Viewer,
}


