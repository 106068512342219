
let downTime = -1
let upTime = -1
let downDuration = 0

document.addEventListener('pointerdown', () => downTime = Date.now())
document.addEventListener('pointerup', () => {
  upTime = Date.now()
  downDuration = upTime - downTime
})

export const getDownDuration = () => downDuration

export const isShortClick = () => downDuration < 300
