import React from 'react'
import create from 'zustand'
import { persist } from 'zustand/middleware'
import * as state from 'client/state/state'
import { Image, ImageComment } from 'types'
import { getHostUrl } from 'utils/app-utils'
import { deferred } from 'client/utils/deferred'
import { isShortClick } from 'client/utils/pointer'
import { ModalContext } from './Modal'
import './ModalImageComment.css'

const useCommentStore = create<{
  author: string
  setAuthor: (author: string) => void,
}>(persist(set => ({
  author: '',
  setAuthor: (author: string) => set({ author }),
}), {
  name: 'comment',
  getStorage: () => sessionStorage,
}))

const Comment: React.FC<{
  collectionIndex: number
  imageIndex: number
  commentIndex: number
}> = ({
  collectionIndex,
  imageIndex,
  commentIndex,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)
  const imageComment = state.getImage(collectionIndex, imageIndex)!.comments![commentIndex]
  const [{ author, comment }, setComment] = React.useState(imageComment)
  const commentStore = useCommentStore()

  const updateComment = (partial: Partial<ImageComment>) => {
    
    const newImageComment = { ...imageComment, ...partial }
    const authorModified = newImageComment.author !== imageComment.author
    const commentModified = newImageComment.comment !== imageComment.comment
    
    const input = inputRef.current!
    const textarea = textareaRef.current!
    input.classList.toggle('modified', authorModified)
    input.value = newImageComment.author
    textarea.classList.toggle('modified', commentModified)
    textarea.value = newImageComment.comment
    
    setComment(newImageComment)
    commentStore.setAuthor(newImageComment.author)
    
    deferred('comment-update', async () => {

      const image = state.getImage(collectionIndex, imageIndex)!
      image.comments![commentIndex] = newImageComment

      await state.updateImage(collectionIndex, imageIndex, image, { thenLoad: false })

      input.classList.toggle('modified', false)
      textarea.classList.toggle('modified', false)

    }, { delay: 1500 })
  }
 
  const deleteComment = () => {
    const image = state.getImage(collectionIndex, imageIndex)!
    image.comments!.splice(commentIndex, 1)
    state.updateImage(collectionIndex, imageIndex, image)
  }

  return (
    <div className="Comment">
      <input
        ref={inputRef}
        type="text"
        value={author}
        onChange={e => updateComment({ author: e.target.value })}
      />
      <textarea
        ref={textareaRef}
        rows={3}
        value={comment}
        onChange={e => updateComment({ comment: e.target.value })}
      />
      <button onClick={deleteComment}>delete</button>
    </div>
  )
}

export const ModalImageComment: React.FC<{
  image: Image
  collectionIndex: number
  imageIndex: number
}> = ({
  image,
  collectionIndex,
  imageIndex,
}) => {

  const divRef = React.useRef<HTMLDivElement>(null)
  const { setModal } = React.useContext(ModalContext)
  const fullurl = getHostUrl(image.url)

  React.useEffect(() => {
    document.scrollingElement?.classList.add('modal-open')
    return () => document.scrollingElement?.classList.remove('modal-open')
  }, [])

  const { author: authorFromStore } = useCommentStore()
  const addNewComment = () => {
    const author = authorFromStore || 'anonymous'
    const comment = ''
    const date = Date.now()
    const comments = [...(image.comments ?? []), { author, comment, date }]
    state.updateImage(collectionIndex, imageIndex, { comments })
  }

  return (
    <div
      ref={divRef}
      className="Modal ImageComment"
      onClick={e => {
        if (e.target === divRef.current && isShortClick()) {
          setModal({ mode: 'none' })
        }
      }}
    >
      {/* <div className="Image" style={{ backgroundImage: `url("${fullurl}")` }}/> */}
      <div className="Image">
        <img src={fullurl} alt="" />
      </div>
      <div className="Comments">
        {image.comments?.map((comment, commentIndex) => (
          <Comment key={comment.date ?? commentIndex} {...{ collectionIndex, imageIndex, commentIndex }}/>
        ))}
        <div style={{ flexBasis: '16px' }}/>
        <button onClick={addNewComment}>Add comment</button>
      </div>
    </div>
  )
}
