import React from 'react'

const isMobile = () => window.innerWidth < 1000

export const useMobileEffect = (cb: (isMobile: boolean) => void) => {
  React.useEffect(() => {
    const onResize = () => {
      cb(isMobile())
    }
    cb(isMobile())
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useIsMobile = () => {
  const [mobile, setMobile] = React.useState(isMobile())
  React.useEffect(() => {
    const onResize = () => {
      const mobileNew = isMobile()
      if (mobileNew !== mobile) {
        setMobile(mobileNew)
      }
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return mobile
}