import React from 'react'

type VideoType = React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLElement>, HTMLVideoElement>
export const VideoLoop = React.forwardRef<HTMLVideoElement, VideoType>((props, ref) => {
  const innerRef = React.useRef<HTMLVideoElement>(null)
  return (
    <video
      ref={value => {
        if (typeof ref === 'function') {
          ref(value)
        } else if (ref) {
          ref.current = value
        }
        (innerRef as any).current = value
      }}
      loop
      muted
      onPointerOver={() => innerRef.current?.play().catch(() => console.log(`oups "pause" happens before "play" init.`))}
      onPointerOut={() => innerRef.current?.pause()}
      {...props} />
  )
})
