import dotenv from 'dotenv'
import { parseEnv } from './utils/parseEnv'

dotenv.config()

export const isDevelopment = process.env.NODE_ENV === 'development'
export const isProduction = !isDevelopment

export const {
  CLIENT_DEV_PORT = '5555',

  // DATA_ROOT = './data/joseph',
  // FILE_DIR = '/Volumes/hipoly-8-drive/GoogleDrive/Drive partagés/WORKS/Edelweiss - Occasional Assets - Shoot/Shoot/',  
  DATA_ROOT = './data/final',
  FILE_DIR = '/Volumes/hipoly-8-drive/GoogleDrive/Drive partagés/WORKS/Edelweiss - Occasional Assets - Shoot/Shoot_DEF/',

  REACT_APP_PROTOCOL = 'http:',
  REACT_APP_HOSTNAME = 'localhost',
  REACT_APP_SERVER_PORT = '4444',
  REACT_APP_GLOBAL_ROUTE = '/assets',

  // re-export SERVER_PORT for clarity on server side
  SERVER_PORT = REACT_APP_SERVER_PORT,
} = process.env

export const {
  REACT_APP_USE_LOCAL_SERVER = false,
  REACT_APP_SHOW_COMMENT = false,
} = parseEnv(process.env).booleans

export const SERVER_ROOT = (isDevelopment
  ? (REACT_APP_USE_LOCAL_SERVER
    // local server
    ? `${REACT_APP_PROTOCOL}//${REACT_APP_HOSTNAME}:${REACT_APP_SERVER_PORT}${REACT_APP_GLOBAL_ROUTE}`
    // remote server
    : `https://edelweiss.josephm.fr${REACT_APP_GLOBAL_ROUTE}`
  )
  : REACT_APP_GLOBAL_ROUTE
)

export const IMAGE_PREFIX = 'EOA-'
export const IMAGES_PER_PAGE = 4

export const {
  DATA_PATH = `${DATA_ROOT}/data.yaml`,
  DATA_COMMENTS_PATH = `${DATA_ROOT}/comments.yaml`,
  DATA_FILE_INFO = `${DATA_ROOT}/file-info.yaml`,
} = process.env
