export const parseEnv = (env: { [key: string]: string | undefined }) => {
  const newEnv = {
    booleans: {},
    numbers: {},
    strings: {},
  } as {
    booleans: Record<string, boolean>
    numbers: Record<string, number>
    strings: Record<string, string>
  } & {
    [key: string]: number | string | boolean | undefined | null
  }
  for (const key in env) {
    const value = env[key]
    const valueLowercase = value?.toLowerCase?.() // should be a string, but sometimes it's not (ex: boolean)
    const newValue = (
      valueLowercase === 'true' ? true :
      valueLowercase === 'false' ? false :
      valueLowercase === 'null' ? null :
      !isNaN(Number(value)) ? Number(value) : 
      value
    )
    newEnv[key] = newValue
    const newValueType = typeof newValue
    if (newValueType === 'boolean') {
      newEnv.booleans[key] = newValue as boolean
    }
    else if (newValueType === 'number') {
      newEnv.numbers[key] = newValue as number
      if (newValue === 1) {
        newEnv.booleans[key] = true
      }
      if (newValue === 0) {
        newEnv.booleans[key] = false
      }
    }
    else if (newValueType === 'string') {
      newEnv.strings[key] = newValue as string
    }
  }
  return newEnv
}